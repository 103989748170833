import React from "react";
import { EnvelopeFill, PhoneFill, PinMapFill } from "react-bootstrap-icons";
import { Heading, Title } from "../../components/Headings";
import SocialIcons from "../../components/SocialIcons";

const ContactDetails = (props) => {
  return (
    <>
      <Heading label={props.heading} />
      <ul className="contact_details_li">
        <li>
          <PinMapFill />

          <span>{props.address}</span>
        </li>

        <li>
          <EnvelopeFill /> <a href={`mailto:${props.email}`}>{props.email}</a>
        </li>

        <li>
          <PhoneFill /> <a href={`tel:${props.phone}`}>{props.phone}</a>
        </li>
      </ul>

      <Title label="Follow us on:" />

      <SocialIcons social={props?.social}/>
    </>
  );
};

export default ContactDetails;
