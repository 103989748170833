const PriceData = [
  {
    label: "Starter",
    price: "18",
    cent: "25",
    yearly: false,
    monthly: true,
    features: [
      {
        item: "Image, Video, Document",
      },
      {
        item: "Manage screens remotely",
      },
      {
        item: "From cloud portal",
      },
      {
        item: "Work offline",
      },
      {
        item: "Background Music",
      },
      {
        item: "Access to Asset and Playlist",
      },
      {
        item: "Dashboard Login",
      },
    ],
    moreFeature: "",
  },
  {
    label: "Standard",
    price: "21",
    cent: "25",
    yearly: false,
    monthly: true,
    features: [
      {
        item: "Canva Integration",
      },
      {
        item: "App Integration (20+ Apps)",
      },
      {
        item: "Playlist Media",
      },
      {
        item: "Schedule Media",
      },
      {
        item: "Split Screen",
      },
      {
        item: "Assigned Account Manager for Assistance.",
      },
    ],
    moreFeature: "And all features from Starter",
  },
  {
    label: "Pro",
    price: "25",
    cent: "00",
    yearly: false,
    monthly: true,
    features: [
      {
        item: "Canva Template Design Assistance",
      },
      {
        item: "Assitaance with Digital Flyer and Menu Board Design",
      },
      {
        item: "Optin to have account manager fully manage media",
      },
      {
        item: "Proof of Play Analytics",
      },
      {
        item: "Technical Support",
      },
    ],
    moreFeature: "And all features from Standard",
  },

  // {
  //   label: "Enterprise",
  //   price: "15",
  //   custom: "Call",
  //   yearly: false,
  //   monthly: false,
  //   features: [
  //     {
  //       item: "Advanced Data Security",
  //     },
  //     {
  //       item: "Dedicated Support",
  //     },
  //     {
  //       item: "Manage Services",
  //     },
  //     {
  //       item: "Custom Integration",
  //     },
  //   ],
  // },
];

export default PriceData;
