import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ApplicationCards from "./ApplicationCards";
import ApplicationData from "./ApplicationData";
import { PrimaryButton } from "../../../components/Button";
import "./index.css";
import { Heading, SubHeading } from "../../../components/Headings";

const ApplicationHome = (props) => {
  const [appData, setSerData] = useState(ApplicationData);


  return (
    <div
      className="application_section py-5"
      style={{
        backgroundImage: `url(${props.bgImage})`,
        backgroundColor: "#f3f3f3",
        backgroundAttachment: "fixed",
      }}
    >
      <Container>
        <div className="section_top_content">
          <SubHeading label={props.SubHeading} />
          <Heading label={props.Heading} />
          <div
                dangerouslySetInnerHTML={{ __html: props.secDesc }}
                className="section_short_desc"
              />
          {/* <p className="section_short_desc">{props.secDesc}</p> */}
        </div>
        <Row>
          <Col>
            <div className="application_cards">
              <ApplicationCards appData={props.application && props.application} />
            </div>
          </Col>
        </Row>

        {/* <PrimaryButton slug={props.btnSlug} label={props.btnLabel} /> */}
      </Container>
    </div>
  );
};

export default ApplicationHome;
