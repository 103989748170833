import React, { Fragment, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink, Link } from "react-router-dom";
import { PrimaryButton } from "../Button";
import { navHome, navLogin } from "../global/Slug";
import logo from '../../image/logo.png';

const MenuItems = ({ menuData }) => {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 767px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 767px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  // let navigate = useNavigate();

  const [show, setShow] = useState(true);
  const showDropdown = () => {
    setShow(true);
  };
  const hideDropdown = () => {
    setShow("");
  };

  const [slideMenu, setSlideMenu] = useState();

  const hideSlideMenu = () => {
    setSlideMenu(false);
  };

  const showSlideMenu = () => {
    setSlideMenu(true);
  };
  return (
    <>
      <header id="header">
        {["md"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            className=""
            expanded={slideMenu}
          >
            <Container>
              <Link to={navHome}>
                <img
                  // src={process.env.PUBLIC_URL + "/images/logo.png"}
                  src={logo}
                  // src={`${process.env.PUBLIC_URL}/images/logo.png`}
                  // src="images/logo.png"
                  style={{ maxWidth: "180px" }}
                  alt="logo"
                />
              </Link>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                onClick={showSlideMenu}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header
                  closeButton
                  onClick={hideSlideMenu}
                ></Offcanvas.Header>

                <Offcanvas.Body>
                  <Nav className="justify-content-center" id="primary-menu">
                    {menuData.map(function (navVal, index) {
                      const {
                        label,
                        slug,
                        dropDown,
                        megaMenu,
                        isDropdown,
                        redirect,
                      } = navVal;

                      return (
                        <Fragment key={label}>
                          {isDropdown ? (
                            <>
                              <NavDropdown
                                title={label}
                                // as={Link}
                                // to={slug}
                                // href={slug}
                                // onToggle={() => {
                                //   window.location.href = slug;
                                // }}
                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                                className={`sub-menu ${
                                  megaMenu && navVal.gridCol === "3"
                                    ? "mega-menu grid-3"
                                    : megaMenu && navVal.gridCol === "2"
                                    ? "mega-menu grid-2"
                                    : " "
                                }`}
                                // onToggle={() => navigate(slug)}
                                {...(!matches ? { show: show } : {})}
                                // show={show}
                                // show={show === index ? show : ""}
                                onMouseEnter={() => showDropdown()}
                                onMouseLeave={() => hideDropdown()}
                                onClick={() => hideDropdown()}
                              >
                                <div className="mega_menu_animation">
                                  <div className="menu-wrap">
                                    {dropDown.map(function (elemDrop, i) {
                                      let slug =
                                        label == "Applications"
                                          ? "application/" + elemDrop.id
                                          : elemDrop?.slug;
                                      return (
                                        <NavLink
                                          to={slug}
                                          key={i}
                                          target={elemDrop?.target || "_self"}
                                          onClick={hideSlideMenu}
                                        >
                                          <span className="submenu-title">
                                            {elemDrop.label}
                                          </span>
                                          <p className="submenu-item-desc">
                                            {elemDrop?.subItemDesc}
                                          </p>
                                        </NavLink>
                                      );
                                    })}
                                  </div>
                                </div>
                              </NavDropdown>
                            </>
                          ) : redirect ? (
                            <a
                              href={slug}
                              className="nav-link"
                              target={navVal.target || "_self"}
                            >
                              {label}
                            </a>
                          ) : (
                            <NavLink
                              to={slug}
                              className="nav-link"
                              target={navVal.target || "_self"}
                              onClick={hideSlideMenu}
                            >
                              {label}
                            </NavLink>
                          )}
                        </Fragment>
                      );
                    })}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>

              <div className="header_button_section">
                {/* <PrimaryButton slug={navFreeDemo} label="Book a Free Demo" /> */}
                <PrimaryButton slug={navLogin} label="Login / Register" />
              </div>
            </Container>
          </Navbar>
        ))}
      </header>
    </>
  );
};

export default MenuItems;
