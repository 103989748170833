import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PrimaryButton, OutlineButton } from "../Button";
import "./index.css";

const Banner = (props) => {
  return (
    <div
      className="page-header-banner"
      style={{
        backgroundImage: `url(${props.bgImage})`,
        backgroundAttachment: "fixed",
      }}
    >
      <Container>
        <Row>
          <Col className="banner_content">
            <h4 className="pageTitle">{props.title}</h4>
            <h1 className="banner_heading">{props.heading}</h1>
            <p className="subheading">{props.subheading}</p>

            <div className="banner-button-group">
              {props.Fbutton ? (
                <PrimaryButton slug={props?.Furl} label={props?.Fbutton} />
              ) : (
                ""
              )}

              {props.Lbutton ? (
                <OutlineButton slug={props?.Lurl} label={props?.Lbutton} />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
