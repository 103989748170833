import {
  navPricing,
  navAbout,
  navHome,
  navTermsOfUse,
  navSecurity,
  navPrivacyPolicy,
  navReturnPolicy,
  navContact,
  navFeatures,
  navFaqs,
  navSupport,
} from "../global/Slug";

const FooterNavData = [
  {
    navHeading: "Useful Link",
    menuItems: [
      {
        label: "Home",
        slug: navHome,
      },
      {
        label: "About Us",
        slug: navAbout,
      },

      {
        label: "Pricing",
        slug: navPricing,
      },

      {
        label: "Features",
        slug: navFeatures,
      },

      ,
    ],
  },

  {
    navHeading: "Support",
    menuItems: [
      {
        label: "Contact",
        slug: navSupport,
      },
      // {
      //   label: "Help Center",
      //   slug: "/",
      // },

      // {
      //   label: "Learn",
      //   slug: "/",
      // },

      {
        label: "FAQ's",
        slug: navFaqs,
      },
    ],
  },

  // {
  //   navHeading: "Company",
  //   menuItems: [
  //     {
  //       label: "Terms of use",
  //       slug: navTermsOfUse,
  //     },

  //     {
  //       label: "Security",
  //       slug: navSecurity,
  //     },

  //     {
  //       label: "Privacy Policy",
  //       slug: navPrivacyPolicy,
  //     },

  //     {
  //       label: "Return Policy",
  //       slug: navReturnPolicy,
  //     },
  //   ],
  // },
];

export default FooterNavData;
