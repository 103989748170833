import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Submit } from "../../components/Button";
import { Title } from "../../components/Headings";
import OrderDetail from "./OrderDetail";
import PaymentOption from "./PaymentOption";
import { useFormik } from "formik";
import { toast } from "react-toastify"; //toast

const validate = (values) => {
  const errors = {};

  if (!values.fname) {
    errors.fname = "Enter first name";
  }
  if (!values.lname) {
    errors.lname = "Enter last name";
  }
  if (!values.company_name) {
    errors.company_name = "Enter company name";
  }
  if (!values.email) {
    errors.email = "Enter email";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.phone) {
    errors.phone = "Enter phone number";
  }
  if (!values.local_address) {
    errors.local_address = "Enter your address";
  }

  if (!values.city) {
    errors.city = "Enter city name";
  }
  if (!values.state) {
    errors.state = "Enter state name";
  }
  if (!values.zip_code) {
    errors.zip_code = "Enter Zip Code";
  }

  return errors;
};
const CheckoutForm = ({ priceData }) => {
  const [CheckoutFormData, setCheckoutFormData] = useState([]);
  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      company_name: "",
      email: "",
      phone: "",
      local_address: "",
      address_details: "",
      city: "",
      state: "",
      zip_code: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      let formData = new FormData();
      setCheckoutFormData(values);
      GoToPAymentLink(priceData?.payment_link);
    },
  });
  function GoToPAymentLink(paymentLink) {
    // let path = `https://techlabbusiness.com/techlab-admin/web/payment?pID=${PriceID}`;
    window.location.href = paymentLink;
  }
  useEffect(() => {
    const CheckoutFormDataList = { ...CheckoutFormData, ...priceData };
    localStorage.setItem(
      "CheckoutFormData",
      JSON.stringify(CheckoutFormDataList)
    );
  }, [CheckoutFormData]);
  return (
    <>
      <div className="checkout_form">
        <Form onSubmit={formik.handleSubmit}>
          <div className="checkout_form_left">
            <Title label="Billing Details" />
            <div className="col2">
              <Form.Group className="mb-3" controlId="formFname">
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  name="fname"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fname}
                />
                {formik.errors.fname && (
                  <div className="error-message">{formik.errors.fname}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formLname">
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  name="lname"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lname}
                />
                {formik.errors.lname && (
                  <div className="error-message">{formik.errors.lname}</div>
                )}
              </Form.Group>
            </div>

            <Form.Group className="mb-3" controlId="formCompany">
              <Form.Control
                type="text"
                placeholder="Company Name (Optional)"
                name="company_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.company_name}
              />
              {formik.errors.company_name && (
                <div className="error-message">
                  {formik.errors.company_name}
                </div>
              )}
            </Form.Group>

            <div className="col2">
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.errors.email && (
                  <div className="error-message">{formik.errors.email}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Control
                  type="text"
                  placeholder="Phone"
                  name="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                {formik.errors.phone && (
                  <div className="error-message">{formik.errors.phone}</div>
                )}
              </Form.Group>
            </div>

            <Form.Group className="mb-3" controlId="formAddress">
              <Form.Control
                type="text"
                placeholder="Address"
                name="local_address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.local_address}
              />
              {formik.errors.local_address && (
                <div className="error-message">
                  {formik.errors.local_address}
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formAddress2">
              <Form.Control
                type="text"
                placeholder="Address Line 2 (Optional)"
                name="adress_details"
              />
            </Form.Group>

            <div className="col2">
              <Form.Group className="mb-3" controlId="formCity">
                <Form.Control
                  type="text"
                  placeholder="City"
                  name="city"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                />
                {formik.errors.city && (
                  <div className="error-message">{formik.errors.city}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formState">
                <Form.Control
                  type="text"
                  placeholder="State"
                  name="state"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                />
                {formik.errors.state && (
                  <div className="error-message">{formik.errors.state}</div>
                )}
              </Form.Group>
            </div>

            <Form.Group className="mb-3" controlId="formZip">
              <Form.Control
                type="text"
                placeholder="Zip / Postal Code"
                name="zip_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.zip_code}
              />
              {formik.errors.zip_code && (
                <div className="error-message">{formik.errors.zip_code}</div>
              )}
            </Form.Group>
          </div>
          <div className="checkout_form_right">
            <div className="order_details_custom">
              <OrderDetail priceData={priceData}/>
            </div>

            {/* <PaymentOption /> */}

            <Submit label="Place Order" />
          </div>
        </Form>
      </div>
    </>
  );
};

export default CheckoutForm;
