const ContactData = [
  {
    imageUrl: "images/phone.png",
    heading: "Phone",
    desc: "1-800-577-1949",
    slug: "tel:1-800-577-1949",
  },
  {
    imageUrl: "images/email.png",
    heading: "Email",
    desc: "b2bsales@techlabmarketers.com",
    slug: "mailto:b2bsales@techlabmarketers.com",
  },

  {
    imageUrl: "images/location.png",
    heading: "Location",
    desc: "4550 Linden Hill Rd Suite 130 Wilmington, DE 19808",
  },
];

export default ContactData;
