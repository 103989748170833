import React, { useState,useEffect } from "react";
import "./index.css";
import Banner from "../../components/Banner";
import { Col, Container, Row, Table } from "react-bootstrap";
import DownloadData from "./DownloadData";
import useApi from "../../hook/useApi";
import Loader from "../../components/Loader";

const DownloadsAndLinks = () => {
  const { request, response, loading } = useApi();
  const [download, setdownload] = useState();
  useEffect(() => {
    request("GET", "download_link");
  }, []);
  useEffect(() => {
    if (response) {
      const appSub = response.response.data;
      setdownload(appSub);
      
    }
  }, [response]);
  return (
    <>
    {loading ? <Loader/>:
    <>
      <Banner
        title={download?.banner_title}
        heading={download?.banner_sub_title}
        // subheading="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum"
        // Fbutton="Request a Demo"
        // Furl={navFreeDemo}
        // Lbutton="Get a Free Estimate"
        // Lurl={navPricing}
        bgImage={download?.banner_image}
      />

      <section className="download_link_button">
        <Container>
          <Row>
            <Col>
              <Table striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>App Name</th>
                    <th>Download Link</th>
                  </tr>
                </thead>
                <tbody>
                  {download?.download.map(function (elem, index) {
                    return (
                      <>
                        <tr key={elem?.id}>
                          <td key={index}>{index + 1}</td>
                          <td>{elem?.name}</td>
                          <td>
                            <a
                              href={elem?.download_link}
                              className="primaryButton"
                              target="_blank"
                            >
                              Download Now
                            </a>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </section>
      </>
    }
    </>
  );
};

export default DownloadsAndLinks;
