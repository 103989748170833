import React, { useEffect, useState } from "react";
import Banner from "../../components/Banner";
import "./index.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Submit } from "../../components/Button";
import { Heading } from "../../components/Headings";
import DateTimePicker from "../../components/DateTimePicker";
import { Link } from "react-router-dom";
import { navDisclaimer, navTermsOfUse } from "../../components/global/Slug";
import { useFormik } from "formik";
import useApi from "../../hook/useApi";
import { toast } from "react-toastify";
const validate = (values) => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = "Enter first name";
  }
  if (!values.last_name) {
    errors.last_name = "Enter last name";
  }
  if (!values.company_name) {
    errors.company_name = "Enter company name";
  }
  if (!values.scope_work) {
    errors.scope_work = "Enter Scope of Work";
  }
  if (!values.email) {
    errors.email = "Enter email";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.phone) {
    errors.phone = "Enter phone number";
  }

  if (!values.install_address) {
    errors.install_address = "Enter install address";
  }
  // if (!values.install_date_time) {
  //   errors.install_date_time = "select Date time";
  // }
  // if (!values.onsite_attach_file) {
  //   errors.onsite_attach_file = "Upload attachment";
  // }
  if (!values.agree_disclaimer) {
    errors.agree_disclaimer = "select checkbox";
  }
  if (!values.service_agreement) {
    errors.service_agreement = "select checkbox";
  }

  return errors;
};
const OnsiteSupportForm = () => {
  const { request, response, loading, error } = useApi();
  const [DateTime, setDateTime] = useState(new Date());

  function installDate(date) {
    setDateTime(date);
  }
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      company_name: "",
      scope_work: "",
      email: "",
      phone: "",
      install_address: "",
      install_date_time: "",
      onsite_attach_file: "",
      agree_disclaimer: "",
      service_agreement: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      let formData = new FormData();
      formData.append("onsite_attach_file", values.onsite_attach_file);
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("company_name", values.company_name);
      formData.append("scope_work", values.scope_work);
      formData.append("email", values.email);
      formData.append("phone", values.phone);
      formData.append("install_address", values.install_address);
      formData.append("install_date_time", DateTime);
      formData.append("agree_disclaimer", values.agree_disclaimer);
      formData.append("service_agreement", values.service_agreement);
      request("POST", "onsite_support_info", formData);
    },
  });

  useEffect(() => {
    if (response) {
      console.log("response", response);
      if (response?.status == true) {
        toast.success("Support Form submitted Successfully");
        formik.resetForm();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error("Support Form not submitted Successfully");
      }
    }
  }, [response]);
  useEffect(() => {
    if (error) {
      console.log("response", response);
      toast.error(error);
    }
  }, [error]);
  return (
    <>
      <Banner
        // title="Pricing"
        heading="Onsite Install Support"
        // subheading="Explore pricing options for dynamic displays and easy-to-use software. Choose from a One-time, No Subscription, Perpetual License OR a Small Monthly Fee."
        // Fbutton="Request a Demo"
        // Furl={navContact}
        bgImage="images/bannerImage.webp"
      />

      <Container className="onsite_install_form">
        <Row>
          <Col md={12}>
            <div className="onsite_form">
              <Heading label="Fill Your Details" />
              <Form onSubmit={formik.handleSubmit}>
                <div className="col2">
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name}
                    />
                    {formik.errors.first_name && (
                      <div className="error-message">
                        {formik.errors.first_name}
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicLname">
                    <Form.Control
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name}
                    />
                    {formik.errors.last_name && (
                      <div className="error-message">
                        {formik.errors.last_name}
                      </div>
                    )}
                  </Form.Group>
                </div>

                <Form.Group className="mb-3" controlId="formCompany">
                  <Form.Control
                    type="text"
                    name="company_name"
                    placeholder="Company Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.company_name}
                  />
                  {formik.errors.company_name && (
                    <div className="error-message">
                      {formik.errors.company_name}
                    </div>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formScopeofWork">
                  <Form.Control
                    type="text"
                    placeholder="Scope of Work"
                    name="scope_work"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.scope_work}
                  />
                  {formik.errors.scope_work && (
                    <div className="error-message">
                      {formik.errors.scope_work}
                    </div>
                  )}
                </Form.Group>

                <div className="col2">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.errors.email && (
                      <div className="error-message">{formik.errors.email}</div>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formPhone">
                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                    {formik.errors.phone && (
                      <div className="error-message">{formik.errors.phone}</div>
                    )}
                  </Form.Group>
                </div>

                <Form.Group className="mb-3" controlId="formAddress">
                  <Form.Control
                    type="text"
                    name="install_address"
                    placeholder="Install Address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.install_address}
                  />
                  {formik.errors.install_address && (
                    <div className="error-message">
                      {formik.errors.install_address}
                    </div>
                  )}
                </Form.Group>

                <div className="col2">
                  <Form.Group controlId="formDatePicker" className="mb-3">
                    <Form.Label>Choose Install Date & Time</Form.Label>
                    <DateTimePicker handledate={(date) => installDate(date)} />
                  </Form.Group>

                  <Form.Group controlId="formFile" className="mb-3">
                  <span style={{ "marginBottom":"5px" ,"display":"block"}}>Attach pictures if available </span>
                    {/* <Form.Label>Attach pictures if available </Form.Label> */}
                    <Form.Control
                      type="file"
                      title=""
                      name="onsite_attach_file"
                      onInput={(e) =>
                        formik.setFieldValue(
                          "onsite_attach_file",
                          e.target.files[0]
                        )
                      }
                    />
                    {formik.errors.onsite_attach_file ? (
                      <div className="error-message">
                        {formik.errors.onsite_attach_file}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>

                <Form.Group className="mb-3" id="formDisclaimer">
                  <Form.Check
                    defaultChecked={false}
                    type="checkbox"
                    name="agree_disclaimer"
                    onClick={(e) => {
                      formik.setFieldValue(
                        "agree_disclaimer",
                        e.target.checked
                      );
                    }}
                    label={
                      <span>
                        Agree to the &nbsp;
                        <Link
                          to={navDisclaimer}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          disclaimer
                        </Link>
                      </span>
                    }
                  />
                  {formik.errors.agree_disclaimer ? (
                    <div className="error-message">
                      {formik.errors.agree_disclaimer}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3" id="formGridCheckbox">
                  <Form.Check
                    type="checkbox"
                    name="service_agreement"
                    onClick={(e) => {
                      formik.setFieldValue(
                        "service_agreement",
                        e.target.checked
                      );
                    }}
                    label={
                      <span>
                        <Link
                          to={navTermsOfUse}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          I read and understand the service agreement
                        </Link>
                      </span>
                    }
                  />
                  {formik.errors.service_agreement ? (
                    <div className="error-message">
                      {formik.errors.service_agreement}
                    </div>
                  ) : null}
                </Form.Group>

                <Submit label="Submit" loading={loading} />
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OnsiteSupportForm;
