import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import {
  navContact,
  navOnsiteSupport,
  navDigitalSignage,
} from "../../../components/global/Slug";
const ServiceCard = ({ serData }) => {
  return (
    <>
      {serData && serData.map(function (elem, i) {
        const { name, icon_image } = elem;

        return (
          <Card key={i}>
            <Link to={navDigitalSignage}>
              <div className="card_image">
                <Card.Img variant="top" src={icon_image} />
              </div>
              <Card.Body>
                <Card.Title>{name}</Card.Title>
              </Card.Body>
            </Link>
          </Card>
        );
      })}
    </>
  );
};

export default ServiceCard;
