import React, { useEffect, useState } from "react";
import Banner from "../../../components/Banner";
import { navContact } from "../../../components/global/Slug";
import ImageWithText from "../../../components/ImageWithText";
// import ServiceData from "./ServiceData";
import "./index.css";
import Loader from "../../../components/Loader";
import useApi from "../../../hook/useApi"; //api calling

const ServiceSec = (props) => {
  return (
    <>
      <ImageWithText
        imageUrl={props.innerImage}
        heading={props.label}
        content={props.desc}
        btnLabel={props.btnLabel}
        btnUrl={props.btnUrl}
      />
    </>
  );
};

const Services = () => {
  const [serviceData, setserviceData] = useState([]);
  // const [isLoading, setLoading,loading] = useState(false);
  const { request, response, loading } = useApi();
  useEffect(() => {
    request("GET", "get_home_page_info?type=service");
  }, []);

  useEffect(() => {
    if (response) {
      setserviceData(response.response.data);
    }
  }, [response]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Banner
            // title="Services"
            heading={serviceData?.banner_title}
            subheading={serviceData?.banner_sub_title}
            Fbutton={serviceData?.banner_button_title}
            Furl={serviceData?.banner_button_url}
            bgImage={serviceData?.banner_image}
          />

          <div className="services_sections">
            {serviceData.service_data &&
              serviceData.service_data.map(function (elem, i) {
                const { image, name, description, button_text, button_url } =
                  elem;
                return (
                  <ServiceSec
                    innerImage={image}
                    label={name}
                    desc={description}
                    key={i}
                    btnLabel={button_text}
                    btnUrl={button_url}
                  />
                );
              })}
          </div>
        </>
      )}
    </>
  );
};

export default Services;
