import React from "react";
import Banner from "../../../components/Banner";
import ImageWithText from "../../../components/ImageWithText";

const LobbyAndOffices = () => {
  return (
    <>
      <Banner
        title="Importance of Digital Signage in"
        heading="Lobby and Office"
        // subheading="57% of communications professionals are using corporate digital signage solutions which leads to 21% higher productivity."
        // Fbutton="Request a Demo"
        // Furl={navFreeDemo}
        bgImage="images/bannerImage.webp"
      />

      <ImageWithText
        imageUrl="images/office.jpg"
        heading="Lobby and Office"
        content="<p>
Tech Lab Signage is a sophisticated yet simple way to create beautiful content and manage your digital signs for your business.
</p>
<p>
We welcome and keep our visitors informed with Screen Zones, which help you display main slides and apps such as Weather, Traffics, News, etc.
</p>
<p>
Tech Lab Signage has free templates for all occasions: COVID-19 Health & Safety, Holiday, Birthday, Upcoming Events, Welcome special guests? We have free templates for all occasions.
</p><p>
We can help you inform and entertain visitors with weather, traffics, social media walls, news, and more. Tech Lab Signage has 90+ Apps that can help to keep your content fresh and your visitors engaged.
</p>

<p>
You can communicate your calendar to share upcoming events with your visitors. Tech Lab Signage automatically sync with Google or Microsoft Calendar to remind you of upcoming events.
</p>
<p>
Schedule Your Content Ahead! Save time and reduce manual work by scheduling content ahead. You can also update your content any time from TLS web portal.
</p>
<p>
Health & Safety Messages! Remind your visitors—and employees—of hygiene practices and safety procedures to keep everyone safe.
</p>"
      />
    </>
  );
};

export default LobbyAndOffices;
