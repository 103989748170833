import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PrimaryButton, OutlineButton } from "../../../components/Button";
import "./index.css";
import anim_image from '../../../image/anim.webp';
import anim2_image from '../../../image/anim2.webp';
const Banner = (props) => {
  return (
    <div
      className="banner_section"
      style={{
        backgroundImage: `url(${props.bgImage})`,
        backgroundAttachment: "fixed",
      }}
    >
      <Container>
        <img src={anim_image} className="animated_image" />
        <img src={anim2_image} className="animated_image2" />
        <Row>
          <Col className="banner_content">
            <h1 className="banner_heading">{props.Bheading}</h1>
            <p className="subheading">{props.BSubhead}</p>

            <div className="banner-button-group">
              <PrimaryButton slug={props.Furl} label={props.Fbutton} />
              <OutlineButton slug={props.Lurl} label={props.Lbutton} />
            </div>

            <div className="bottom_text">
              <p>{props.BottomText}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
