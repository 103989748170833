import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ServiceCard from "./ServiceCard";
import ServiceData from "../../Products/Services/ServiceData";
import { Heading, SubHeading } from "../../../components/Headings";
import "./index.css";

const ServiceHome = (props) => {
  const [serData, setSerData] = useState(ServiceData);
  return (
    <div className="service_section py-5">
      <Container>
        <SubHeading label={props.SubHeading} />
        <Heading label={props.Heading} />
        <Row>
          <Col>
            <div className="service_cards">
              <ServiceCard serData={props.service} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServiceHome;
