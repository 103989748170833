import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Loader from "../../components/Loader";
import SimpleContent from "../../components/SimpleContent";
import useApi from "../../hook/useApi";

const CompanyInfo = () => {
  const { id } = useParams();
  const [details, setdetails] = useState();
  const { request, response, loading } = useApi(); //change
  // const { request: reqUserDetail, response: respUserDetails } = useApi();
  useEffect(() => {
    request("GET", "get_page_details?url_slug=" + id);
  }, [id]);

  useEffect(() => {
    if (response) {
      if (response.status) {
        setdetails(response.response.data);
      }
    }
  }, [response]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumb title={details?.title}/>
          <div className="policy_section">
            <SimpleContent
              // label={details?.sub_title}
              content={details?.desc}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CompanyInfo;
