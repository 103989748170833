import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PrimaryButton } from "../../../components/Button";
import { Heading, SubHeading } from "../../../components/Headings";
import "./index.css";

const AboutHome = (props) => {
  return (
    <div
      className="about_section"
      style={{
        backgroundImage: `url(${props.bgImage})`,
        backgroundColor: "#19191b",
      }}
    >
      <Container>
        <Row>
          <Col className="banner_content" md={6}>
            <SubHeading label={props.SubHeading} />
            <Heading label={props.Heading} />
            {/* <p className="secDesc" style={{ color: "inherit" }}>
              {props.secDesc}
            </p> */}
            <div
                dangerouslySetInnerHTML={{ __html: props.secDesc }}
                className="secDesc" 
                style={{ color: "inherit" }}
              />
            <PrimaryButton slug={props.btnSlug} label={props.btnLabel} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutHome;
