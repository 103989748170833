import React from "react";
import { FaqData } from "./FaqData";
import Accordion from "react-bootstrap/Accordion";
import { Col, Container, Row } from "react-bootstrap";
import { Heading } from "../../components/Headings";

export const FaqItems = (props) => {

  return (
    <>
      <section className="accordion_section">
        <Container>
          <Row>
            <Col>
              <Heading label={props.heading} />
              <Accordion defaultActiveKey={[0]} flush>
                {props.faq && props.faq.map(function (elem, index) {
                  const{faq_question,faq_answer}=elem;
                  return (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>{faq_question}</Accordion.Header>
                      <Accordion.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: faq_answer }}
                          className="content"
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
