import React, { useEffect, useState } from "react";
import FeatureSection from "./FeatureSection";
import { navPricing, navFreeDemo } from "../../components/global/Slug";
import "./index.css";
import Banner from "../../components/Banner";
import Loader from "../../components/Loader";
import useApi from "../../hook/useApi";

const Features = () => {
  const { request, response, loading } = useApi();
  const [Feature, setFeature] = useState();
  useEffect(() => {
    request("GET", "get_feature_list");
  }, []);
  useEffect(() => {
    if (response) {
      if (response.response.data) {
        const appSub = response.response.data;
        setFeature(appSub);
      }
    }
  }, [response]);

  // if (loading) {
  //   return <Loader />;
  // }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Banner
            title={Feature?.banner_title}
            heading={Feature?.banner_sub_title}
            subheading={Feature?.banner_short_desc}
            Fbutton={Feature?.banner_button_first_text}
            Furl={Feature?.banner_first_button_url}
            Lbutton={Feature?.banner_second_button_text}
            Lurl={Feature?.banner_second_button_url}
            bgImage={Feature?.banner_image}
          />

          <FeatureSection
            secHeading={Feature?.page_title}
            secSubheading={Feature?.page_sub_title}
            Feature={Feature?.feature_list}
          />
        </>
      )}
    </>
  );
};

export default Features;
