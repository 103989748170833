import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SocialIcons from "../SocialIcons";

const Copyright = (props) => {
  return (
    <>
      <div className="copyright">
        <Container>
          <Row>
            <Col md={12}>
              <p>{props.copyText}</p>
            </Col>
            {/* <Col md={6} className="social_icon_footer">
              <SocialIcons />
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Copyright;
