import React from "react";
import Banner from "../../components/Banner";
import { navFreeDemo } from "../../components/global/Slug";

const FreeDemo = () => {
  return (
    <>
      <Banner
        title="Free Demo"
        heading="Get a Free Demo"
        subheading="95% of retail banks with digital signage are satisfied with their deployment. This is from benefits such as perceived wait times being reduced by as much as 35%."
        Fbutton="Request a Demo"
        Furl={navFreeDemo}
        bgImage="images/bannerImage.webp"
      />
    </>
  );
};

export default FreeDemo;
