import React from "react";
import { Facebook, Instagram, Youtube, Twitter } from "react-bootstrap-icons";
// import SocialData from "./SocialData";
import "./index.css";

export const SocialLink = (props) => {
  return (
    <>
      <li>
        <a href={props.slug} target="_blank">
          {props.name}
        </a>
      </li>
    </>
  );
};

const SocialIcons = ({ social }) => {
  let SocialData = [
    {
      iconName: <Facebook />,
      slug: social?.facebook,
    },
    {
      iconName: <Instagram />,
      slug: social?.instagram,
    },
    {
      iconName: <Twitter />,
      slug: social?.twitter,
    },
    {
      iconName: <Youtube />,
      slug: social?.youtube,
    },
  ];
  return (
    <ul className="social_icons">
      {SocialData.map(function (elem, i) {
        return (
          <React.Fragment key={i}>
            {elem.slug ? (
              <SocialLink slug={elem.slug} name={elem.iconName} />
            ) : null}
          </React.Fragment>
        );
      })}
    </ul>
  );
};

export default SocialIcons;
