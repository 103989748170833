import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import SimpleContent from "../../components/SimpleContent";

const ReturnPolicy = () => {
  return (
    <>
      <Breadcrumb title="Return Policy" />
      <div className="policy_section">
        <SimpleContent
          content="<p>Thank you for your purchase. We hope you are happy with your purchase. However, if you are not completely satisfied with your purchase
for any reason, you may return it to us for a full refund, store credit, or an exchange. Please see below for more information on our return
policy.</p>"
        />

        <SimpleContent
          label="RETURNS"
          content="<p>All returns must be postmarked within thirty (30) days of the purchase date. All returned items must be in new and unused condition, with
all original tags and labels attached.</p>"
        />

        <SimpleContent
          label="RETURN PROCESS"
          content="<p>To return an item, please email customer service at support@optisigns.com to obtain a Return Merchandise Authorization (RMA) number.
After receiving a RMA number, place the item securely in its original packaging and include your proof of purchase, and mail your return to
the following address:</p>"
        />
      </div>
    </>
  );
};

export default ReturnPolicy;
