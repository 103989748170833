import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../../../components/Banner";
import ImageWithText from "../../../components/ImageWithText";
import Loader from "../../../components/Loader";
import useApi from "../../../hook/useApi";
import { IconBox } from "./IconBox";
import "./index.css";

const About = () => {
  const { request, response, loading } = useApi();
  const [Aboutus, setAboutus] = useState();

  useEffect(() => {
    request("GET", "get_aboutus_page_info");
  }, []);
  useEffect(() => {
    if (response) {
      const appSub = response.response.data;
      setAboutus(appSub);
      
    }
  }, [response]);

  if(loading){
    return <Loader />
  }
  return (
    <>
    {loading?<Loader />:
    <>
      <Banner
        title={Aboutus?.about_us_banner_title}
        heading={Aboutus?.about_us_sub_banner_title}
        // subheading="Today, over 18,000 customers, including NASA, Sodexo, Discovery Channel, Virginia Tech, and Nike rely on the Mvix platform to power hundreds of screens across their campuses."
        // Fbutton="Request a Demo"
        // Furl={navFreeDemo}
        bgImage={Aboutus?.about_us_banner_image}
      />

      <section className="about_icons">
        <Container>
          <Row>
            <Col md={12}>
              <div className="about_icon_items">
                <IconBox 
                phone={Aboutus?.phone} 
                phone_icon={Aboutus?.phone_icon} 
                email={Aboutus?.email} 
                email_icon={Aboutus?.email_icon} 
                location={Aboutus?.location} 
                location_icon={Aboutus?.location_icon} 
                 />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <ImageWithText
        imageUrl={Aboutus?.about_us_image}
        heading={Aboutus?.about_us_title}
        content={Aboutus?.about_us_desc}
      />
      </>
  }
    </>
  );
};

export default About;
