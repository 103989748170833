import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Submit } from "../../Button";
import { Heading } from "../../Headings";
import "./index.css";
import { useFormik } from "formik";
import useApi from "../../../hook/useApi";
import { toast } from "react-toastify"; //toast
const validate = (values) => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = "Enter first name";
  }
  if (!values.last_name) {
    errors.last_name = "Enter last name";
  }
  if (!values.email) {
    errors.email = "Enter email";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.phone) {
    errors.phone = "Enter phone number";
  }
  if (!values.company_name) {
    errors.company_name = "Enter company name";
  }
  if (!values.message) {
    errors.message = "Enter message";
  } else if (values.message.length < 8) {
    errors.message = "Must be 8 characters or less";
  }
  if (!values.type) {
    errors.type = "select support type";
  }
  if (!values.screens) {
    errors.screens = "Enter Screen number";
  }
  // if (!values.support_image) {
  //   errors.support_image = "Upload attachment";
  // }

  return errors;
};

const SupportForm = () => {
  const { request, response, loading } = useApi();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      company_name: "",
      type: "retail",
      screens: "",
      message: "",
    },
    validate,
    isValidating:true,
    onSubmit: (values, { resetForm }) => {
      let formData = new FormData();
      formData.append("support_image", values.support_image);
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);
      formData.append("phone", values.phone);
      formData.append("company_name", values.company_name);
      formData.append("type", values.type);
      formData.append("screens", values.screens);
      formData.append("message", values.message);
      request("POST", "support_contact_info", formData);
    },
  });
  useEffect(() => {
    if (response) {
      if (response?.status) {
        toast.success("Support Form submitted Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        // formik.resetForm();
      } else {
        toast.error("Support Form not submitted Successfully");
      }
    }
  }, [response]);
  return (
    <>
      <div className="registeration_form">
        <Form onSubmit={formik.handleSubmit}>
          <Heading label="Ready to Get Started?" />

          <div className="col2">
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Control
                type="text"
                placeholder="First Name"
                name="first_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
              />
              {formik.errors.first_name && (
                <div className="error-message">{formik.errors.first_name}</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicLname">
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="last_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
              />
              {formik.errors.last_name && (
                <div className="error-message">{formik.errors.last_name}</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                placeholder="Enter email"
              />
              {formik.errors.email && (
                <div className="error-message">{formik.errors.email}</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Control
                type="number"
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                placeholder="Phone"
              />
              {formik.errors.phone && (
                <div className="error-message">{formik.errors.phone}</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formCompanyName">
              <Form.Control
                type="text"
                name="company_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.company_name}
                placeholder="Company Name"
              />
              {formik.errors.company_name && (
                <div className="error-message">
                  {formik.errors.company_name}
                </div>
              )}
            </Form.Group>

            <Form.Select
              aria-label="Default select example"
              name="type"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.type}
            >
              <option value="retail">Retail</option>
              <option value="Office">Office</option>
              <option value="Restaurant">Restaurant</option>
              <option value="Hotel">Hotel</option>
              <option value="Wearhouse">Wearhouse</option>
              <option value="Malls">Malls</option>
              <option value="Others">Others</option>
            </Form.Select>
            {formik.errors.type && (
              <div className="error-message">{formik.errors.type}</div>
            )}
          </div>

          <Form.Group className="mb-3" controlId="formScreens">
            <Form.Control
              type="text"
              name="screens"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.screens}
              placeholder="Number of screens"
            />
            {formik.errors.screens && (
              <div className="error-message">{formik.errors.screens}</div>
            )}
          </Form.Group>

          <Form.Group controlId="formFileLg" className="mb-3">
            <span style={{ "marginBottom":"5px" ,"display":"block"}}>Attach File <span style={{ "color":"red"} }> (Allow file type : .png, .jpg, .pdf, .xlsx, .doc , .docx)</span></span>
            <Form.Control
              type="file"
              title=""
              size="lg"
              name="support_image"
              // value={formik.values.files}
              onInput={(e) =>
                formik.setFieldValue("support_image", e.target.files[0])
              }
            />
            {formik.errors.support_image ? (
              <div className="error-message">{formik.errors.support_image}</div>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formConcern">
            <Form.Control
              as="textarea"
              name="message"
              placeholder="Any special request "
              style={{ height: "100px" }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
            />
            {formik.errors.message && (
              <div className="error-message">{formik.errors.message}</div>
            )}
          </Form.Group>

          <Submit label="Submit" loading={loading} />
        </Form>
      </div>
    </>
  );
};

export default SupportForm;
