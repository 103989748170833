import React from "react";
import { Card } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../../components/Button";
import { navCheckout, navContact } from "../../../components/global/Slug";
import Loader from "../../../components/Loader";

const PriceCards = ({ priceData, showForm }) => {
  if (!priceData) {
    <Loader />;
  }

  return (
    <>
      {priceData &&
        priceData.map(function (elem, i) {
          const card_price = elem.price_amount.split(".");
          const Plan = elem.price_plan == "monthly" ? "month" : "year";
          return (
            <Card key={i} className="pricing_cards">
              <Card.Body>
                <Card.Title>{elem?.price_title}</Card.Title>

                <Card.Text className="price_sec">
                  {/* {elem.price_plan == "monthly" ? (
                    <> */}
                  <span className="sub_heading">{elem?.price_title} at</span>
                  <span className="price_amt">
                    <span className="price_symbol">{elem?.currency_code}</span>
                    {card_price[0]}.
                    <span className="cent_price">{card_price[1]}</span>
                    <span className="per_month">/{Plan}</span>
                  </span>
                  <span className="sub_heading">
                    {elem?.price_paln_text} Screen
                  </span>
                  {/* </>
                  ) : (
                    <>
                      <span className="price_amt">
                        <span className="price_symbol">$</span>
                        {elem.price_amount}
                      </span>
                    </>
                  )} */}
                </Card.Text>
                <div className="features">
                  {elem.price_description ? (
                    <ul className="features_list">
                      {elem.price_description
                        .split(/\r?\n/)
                        .map((desc, index, arr) => (
                          <React.Fragment key={i}>
                            <li key={i}>
                              {index > 0 && arr.length - 2 >= index ? (
                                <Check2Circle />
                              ) : (
                                ""
                              )}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: desc,
                                }}
                              />
                            </li>
                          </React.Fragment>
                        ))}
                    </ul>
                  ) : null}

                  {/* <span className="all-feature-from">{elem.moreFeature}</span> */}
                </div>
                <div className="bottom_links">
                  {elem.price_plan == "monthly" ||
                  elem.price_plan == "yearly" ? (
                    <>
                      <Link className="primaryButton"
                        to={{
                          pathname: navCheckout,
                        }}
                        state={elem}
                      >
                        Buy Now
                      </Link>
                      {/* <PrimaryButton
                        label="Buy Now"
                        slug={navCheckout}
                        onClickbtn={showForm}
                      /> */}
                    </>
                  ) : (
                    <PrimaryButton label="Talk To Sales" slug={navContact} />
                  )}
                </div>
              </Card.Body>
            </Card>
          );
        })}
    </>
  );
};

export default PriceCards;
