import React from "react";
import { Table } from "react-bootstrap";
import { Title } from "../../components/Headings";

const OrderDetail = ({priceData}) => {
  return (
    <>
      <Title label="Your Order" />

      <div className="order-table">
        <Table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr className="order-details">
              <td>{priceData?.price_title}</td>
              <td>{priceData?.currency_code} {priceData?.price_amount}</td>
            </tr>

            <tr className="order-subtotal">
              <td>
                <strong>Subtotal</strong>
              </td>
              <td>{priceData?.currency_code} {priceData?.price_amount}</td>
            </tr>
            <tr className="order-total">
              <td>
                <strong>Total</strong>
              </td>
              <td>{priceData?.currency_code} {priceData?.price_amount}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default OrderDetail;
