import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PrimaryButton, OutlineButton } from "../Button";

const FooterAction = (props) => {
  return (
    <>
      <div className="bottom_callaction_section py-5">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section_top_content">
                <h2 className="section_heading">{props.secHeading}</h2>
                <h3 className="section_subheading">{props.secSubheading}</h3>
                {/* <p className="section_desc">{props.secDesc}</p> */}
              </div>
            </Col>

            <Col md={6}>
              <div className="button_groups">
                <PrimaryButton slug={props.fBtnSlug} label={props.fBtnLabel} />
                {/* <OutlineButton slug={props.lBtnSlug} label={props.lBtnLabel} /> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FooterAction;
