import React, { useState, useEffect } from "react";
import "./index.css";
import Banner from "../../components/Banner";
import Loader from "../../components/Loader";
import { FaqItems } from "./FaqTabs";
import useApi from "../../hook/useApi"; //api calling

const Faq = () => {
  const [isLoading, setLoading] = useState(false);
  const { request, response } = useApi(); //change
  const [faqData, setfaqData] = useState();
  useEffect(() => {
    setLoading(true);
    request("GET", "get_faq_list");
  }, []);

  useEffect(() => {
    if (response) {
      setfaqData(response.response.data);
      setLoading(false);
    }
  }, [response]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Banner heading="FAQs" bgImage="images/bannerImage.webp" />
          <FaqItems heading="Frequently Ask Questions" faq={faqData}/>
        </>
      )}
    </>
  );
};

export default Faq;
