import React, { useEffect, useState } from "react";
import Banner from "../../../components/Banner";
import Loader from "../../../components/Loader";
import { navContact } from "../../../components/global/Slug";
import DsPricing from "./DsPricing";
import useApi from "../../../hook/useApi";
import "./index.css";

const Pricing = () => {
  const { request, response, loading } = useApi();
  const [Price, setPrice] = useState();

  useEffect(() => {
    request("GET", "get_pricing_list");
  }, []);
  useEffect(() => {
    if (response) {
      if (response.response.data) {
        const appSub = response.response.data;
        setPrice(appSub);
      }
    }
  }, [response]);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Banner
        heading={Price?.banner_title}
        subheading={Price?.banner_sub_title}
        Fbutton={Price?.button_text}
        Furl={Price?.button_url}
        bgImage={Price?.banner_image}
      />

      <DsPricing  {...Price}/>
    </>
  );
};

export default Pricing;
