import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing/DigitalSignagePricing";

import About from "./pages/About/AboutUs";
import FreeDemo from "./pages/FreeDemo";
import Features from "./pages/Features";
import Footer from "./components/footer";
import Contact from "./pages/Contact";
import CompanyInfo from "./pages/CompanyInfo";
import {
  navHome,
  navPricing,
  navAbout,
  navFreeDemo,
  navFeatures,
  navContact,
  navRestaurants,
  navRetail,
  navTermsOfUse,
  navSecurity,
  navPrivacyPolicy,
  navReturnPolicy,
  navCheckout,
  navRemoteWork,
  navOnsiteSupport,
  navGymFitnessCenters,
  navLobbyAndOffices,
  navSchoolAndUniverstiy,
  navDownloadsAndLinks,
  navAppSupported,
  navShop,
  navFaqs,
  navSupport,
  navLogin,
  navRegister,
  navDigitalSignage,
  navThankYou,
} from "./components/global/Slug";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Restaurants from "./pages/Market/Restaurants";
import Retail from "./pages/Market/Retail";
import TermsOfUse from "./pages/TermsOfUse";
import Security from "./pages/Security";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import Checkout from "./pages/Checkout";
import ScrollToTop from "./components/ScrollToTop";
import RemoteWork from "./pages/Market/RemoteWork";
import { navConferenceRooms } from "./components/global/Slug";
import OnsiteSupportForm from "./pages/OnsiteSupportForm";
import GymFitnessCenter from "./pages/Market/GymFitnessCenter";
import ConferenceRooms from "./pages/Market/ConferenceRooms";
import LobbyAndOffices from "./pages/Market/LobbyAndOffices";
import SchoolAndUniversity from "./pages/Market/SchoolAndUniversity";
import DownloadsAndLinks from "./pages/DownloadsAndLinks";
import AppSupported from "./pages/AppSupported";
import Shop from "./pages/Shop";
import Faq from "./pages/Faq";
import Support from "./pages/Support";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Services from "./pages/Products/Services";

import { ToastContainer } from "react-toastify"; //change by hg //https://fkhadra.github.io/react-toastify/migration-v8
import "react-toastify/dist/ReactToastify.css"; //change by hg
import Loader from "./components/Loader";
import ThankYou from "./pages/ThankYou";

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        {/* {isLoading?
        <Loader/>:null

        } */}
        <ScrollToTop />
        <Header />

        <Routes>
          <Route exact path={navHome} element={<Home />} />

          {/* About */}
          <Route exact path={navAbout} element={<About />} />

          {/* Downloads & Links */}
          <Route
            exact
            path={navDownloadsAndLinks}
            element={<DownloadsAndLinks />}
          />

          {/* Pricing */}
          <Route exact path={navPricing} element={<Pricing />} />

          {/* Apps Supported */}
          <Route exact path={navAppSupported} element={<AppSupported />} />

          {/* Applications */}

          <Route
            exact
            path={navConferenceRooms}
            element={<ConferenceRooms />}
          />
          <Route path="application/:id" element={<ConferenceRooms />} />
          {/* <Route
            exact
            path={'/details'}
            element={<ConferenceRooms />}
          /> */}
          <Route exact path={navRemoteWork} element={<RemoteWork />} />
          <Route exact path={navRetail} element={<Retail />} />
          <Route exact path={navRestaurants} element={<Restaurants />} />
          <Route
            exact
            path={navLobbyAndOffices}
            element={<LobbyAndOffices />}
          />
          <Route
            exact
            path={navGymFitnessCenters}
            element={<GymFitnessCenter />}
          />
          <Route
            exact
            path={navSchoolAndUniverstiy}
            element={<SchoolAndUniversity />}
          />

          {/* Features */}
          <Route exact path={navFeatures} element={<Features />} />

          {/* Shop */}
          <Route exact path={navShop} element={<Shop />} />

          {/* FAQs  */}
          <Route exact path={navFaqs} element={<Faq />} />

          {/* Support  */}
          <Route exact path={navSupport} element={<Support />} />

          {/* Login Register  */}
          <Route exact path={navLogin} element={<Login />} />
          <Route exact path={navRegister} element={<Register />} />

          {/* Footer  */}
          <Route path="company/:id" element={<CompanyInfo />} />
          <Route exact path={navFreeDemo} element={<FreeDemo />} />
          <Route exact path={navContact} element={<Contact />} />

          <Route exact path={navTermsOfUse} element={<TermsOfUse />} />
          <Route exact path={navSecurity} element={<Security />} />
          <Route exact path={navPrivacyPolicy} element={<PrivacyPolicy />} />
          <Route exact path={navReturnPolicy} element={<ReturnPolicy />} />

          <Route exact path={navCheckout} element={<Checkout />} />
          <Route
            exact
            path={navOnsiteSupport}
            element={<OnsiteSupportForm />}
          />

          {/* Services  */}

          <Route exact path={navDigitalSignage} element={<Services />} />

          <Route exact path={navThankYou} element={<ThankYou />} />
        </Routes>
        <Footer />
        <ToastContainer />
      </BrowserRouter>
    </>
  );
}

export default App;
