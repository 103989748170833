import React from "react";
import Card from "react-bootstrap/Card";
// import { Link } from "react-router-dom";
// import { PrimaryButton } from "../../components/Button";

const FeaturesCard = ({ feaData }) => {
  // console.log(feaData);
  const imageUrl="images/files.webp";
  return (
    <>
      {feaData && feaData.map(function (elem, i) {
        const data = `${elem?.feature_desc}`;
        return (
          <Card key={i}>
            <div className="card_image">
              <Card.Img variant="top" src={elem.features_icon?elem.features_icon:imageUrl} />
            </div>

            <span className="feature_type">{elem?.feature_type}</span>

            <Card.Body>
              {/* <Link to={elem?.slug} className="card_title_name"> */}
              <Card.Title>{elem?.feature_title}</Card.Title>
              {/* </Link> */}
              <Card.Text>
                <div
                  dangerouslySetInnerHTML={{ __html: data }}
                  className="content"
                />
              </Card.Text>

              {/* <PrimaryButton slug={elem?.slug} label="Learn More" /> */}
            </Card.Body>
          </Card>
        );
      })}
    </>
  );
};

export default FeaturesCard;
