import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./index.css";

const Breadcrumb = (props) => {
  return (
    <div className="breadscrumb">
      <Container>
        <Row>
          <Col className="banner_content">
            <h4 className="pageTitle">{props.title}</h4>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Breadcrumb;
