import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-bootstrap-icons";
import { navHome } from "../../components/global/Slug";
import useApi from "../../hook/useApi";
import "./index.css";

const ThankYou = () => {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  const { request, response, loading } = useApi();
  const query = new URLSearchParams(window.location.search);
  const handleUrl = () => {
    console.log("querygetsuccess", query.get("success"));
    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  };
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    handleUrl();
  }, []);

  useEffect(() => {
    if (
      success &&
      localStorage.getItem("CheckoutFormData") != null &&
      localStorage.getItem("CheckoutFormData").length > 0
    ) {
      let CheckoutFormData = JSON.parse(
        localStorage.getItem("CheckoutFormData")
      );
      // console.log("CheckoutFormData", CheckoutFormData);
      request("POST", "stripe_subcription_paymentInfo", CheckoutFormData);
    }
  }, [success]);

  useEffect(() => {
    if (response?.status) {
      // console.log("response", response);
       localStorage.removeItem('CheckoutFormData')
    }
  }, [response]);
  return (
    <>
      <div className="payement_status">
        <Container>
          <Row>
            {success && sessionId !== "" ? (
              <Col>
                <h2>Your Payment is done Successfully</h2>
                <p>
                  Transaction ID: <span className="order_id">{sessionId}</span>
                </p>
              </Col>
            ) : message ? (
              <Col>
                <h2>Payment canceled</h2>
                <p>{message}</p>
              </Col>
            ) : (
              <Col>
                <h2>SomeThing Went Wrong ,Back to Home</h2>
                <Link
                  className="primaryButton"
                  to={{
                    pathname: navHome,
                  }}
                >
                  Go to Home
                </Link>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ThankYou;
