import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Title } from "../Headings";

const SimpleContent = (props) => {
  const data = `${props.content}`;
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Title label={props.label} />
            <div
              dangerouslySetInnerHTML={{ __html: data }}
              className="content"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SimpleContent;
