import {
  navContact,
  navOnsiteSupport,
  navDigitalSignage,
} from "../../../components/global/Slug";

const ServiceData = [
  {
    label: "Digital Signage Software",
    imageUrl: "images/software.png",
    slug: navDigitalSignage,
    innerImage: "images/software.webp",
    btnLabel: "Book a Free Demo",
    btnUrl: navContact,
    desc: "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p><p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p> ",
  },

  {
    label: "Digital Flyer Design",
    imageUrl: "images/flyer.png",
    slug: navDigitalSignage,
    innerImage: "images/about.jpg",
    btnLabel: "Book a Free Demo",
    btnUrl: navContact,
    desc: "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p><p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p> ",
  },

  {
    label: "Digital Menu Board",
    imageUrl: "images/menu.png",
    slug: navDigitalSignage,
    innerImage: "images/menu-board.webp",
    btnLabel: "Book a Free Demo",
    btnUrl: navContact,
    desc: "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p><p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p> ",
  },

  {
    label: "Onsite Install",
    imageUrl: "images/installation.png",
    innerImage: "images/onsite.jpg",
    slug: navDigitalSignage,
    btnLabel: "Contact Support",
    btnUrl: navOnsiteSupport,
    desc: "<p><strong>USA</strong> and <strong>Canada</strong> we charge <strong>75USD / hour</strong>, other regions please contact support</p> ",
  },
];

export default ServiceData;
