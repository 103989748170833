export const navHome = "/";

export const navDownloadsAndLinks = "/downloads-and-links";

export const navAppSupported = "/app-supported";

export const navShop = "https://techlabbusiness.com/product/digital-signage/2";

export const navFaqs = "/faqs";

export const navSupport = "/support";

export const navLogin = "https://login.techlabsignage.com";

export const navRegister = "/Register";

export const navDigitalSignage = "/digital-signage";
// export const navSoftware = "/digital-signage-software";
// export const navPlayers = "/players";
// export const navServices = "/services";

export const navPricing = "/digital-signage-pricing";
// export const navProjectEstimator = "/project-estimator";

export const navLobbyAndOffices = "/lobby-and-offices";
export const navSchoolAndUniverstiy = "/school-and-university";
export const navConferenceRooms = "/conference-rooms";
export const navGymFitnessCenters = "/gym-and-fitness-centers";
export const navRestaurants = "/restaurants";
export const navRetail = "/retail";
export const navRemoteWork = "/remote-work";
// export const navEducationDigitalSignage = "/education-digital-signage";
// export const navSportsDigitalSignage = "/sports-digital-signage";
// export const navWorkshipVenues = "/workship-venues";

// export const navEntertainment = "/entertainment";
// export const navGovernment = "/government";
// export const navHealthcare = "/healthcare";
// export const navHotels = "/hotels";
// export const navManufacturing = "/manufacturing";
// export const navBanks = "/banks";

export const navAbout = "/about-us";
// export const navDesignGallery = "/design-gallery";
// export const navInstallGallery = "/install-gallery";
// export const navSuccessStories = "/success-stories";
// export const navResourcesContent = "/resources-content";
// export const navDigitalSignageInsight = "/digital-signage-insight";

export const navFreeDemo = "/free-demo";
export const navFeatures = "/features";
export const navContact = "/contact-us";

export const navTermsOfUse = "/terms-of-use";
export const navSecurity = "/security";
export const navPrivacyPolicy = "/privacy-policy";
export const navReturnPolicy = "/return-policy";
export const navDisclaimer = "/company/disclaimer";

export const navCheckout = "/checkout";
export const navOnsiteSupport = "/onsite-support";

export const navThankYou = "/thank-you";
