import React, { useState ,useEffect} from "react";
import { Navigate, useLocation,useParams } from "react-router-dom";
import Banner from "../../../components/Banner";
import ImageWithText from "../../../components/ImageWithText";
import Loader from "../../../components/Loader";
import useApi from "../../../hook/useApi"; //api calling
const ConferenceRooms = () => {
const {id} = useParams();
const [details, setdetails] = useState();
  const [isLoading, setLoading] = useState(false);
  const { request, response } = useApi(); //change
  // const { request: reqUserDetail, response: respUserDetails } = useApi();
  useEffect(() => {
    setLoading(true);
    request("GET", "get_application_details_info?url_slug="+id);
  }, [id]);

  useEffect(() => {
    if (response) {
      if(response.status){
        setdetails(response.response.data);
        setLoading(false);
      }
    
    }
  }, [response]);

  return (
    <>
   {isLoading ? <Loader />:
  <>
      <Banner
        title={details?.banner_heading}
        heading={details?.banner_sub_heading}
        // subheading="Are you ready to wow customers with digital signage for retail?"
        // Fbutton="Request a Demo"
        // Furl={navFreeDemo}
        bgImage={details?.banner_image}
      />

      <ImageWithText
        imageUrl={details?.image}
        heading={details?.title}
        content={details?.description}
      />
    </>
}
    </>
  );
};

export default ConferenceRooms;
