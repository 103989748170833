import React from "react";
import IconWithText from "../../../components/IconWithText";
// import ContactData from "../../../components/global/ContactData";

export const IconBox = ({phone,phone_icon,email,email_icon,location_icon,location}) => {
  let ContactData = [
    {
      imageUrl: phone_icon,
      heading: "Phone",
      desc: phone,
      slug: `tel:${phone}`,
    },
    {
      imageUrl:email_icon,
      heading: "Email",
      desc: email,
      slug: `mailto:${email}`,
    },
  
    {
      imageUrl: location_icon,
      heading: "Location",
      desc: location,
    },
  ];
  return (
    <>
      {ContactData.map(function (elem, i) {
        return (
          <IconWithText key={i}
            imageUrl={elem.imageUrl}
            heading={elem.heading}
            desc={elem.desc}
            slug={elem?.slug}
          />
        );
      })}
    </>
  );
};
