import React from "react";
import "./index.css";
import Banner from "../../components/Banner";
import LoginForm from "../../components/Form/Login";
import { Col, Container, Row } from "react-bootstrap";

const Login = () => {
  return (
    <>
      <Banner
        // title="Available"
        heading="Login"
        // subheading="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum"
        // Fbutton="Request a Demo"
        // Furl={navFreeDemo}
        // Lbutton="Get a Free Estimate"
        // Lurl={navPricing}
        bgImage="images/bannerImage.webp"
      />

      <section className="login_form_section">
        <Container>
          <Row>
            <Col md={12}>
              <LoginForm />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Login;
