import React, { useEffect, useState } from "react";
import "./index.css";
import Banner from "../../components/Banner";
import SupportForm from "../../components/Form/SupportForm";
import { Col, Container, Row } from "react-bootstrap";
import ContactDetails from "../Contact/ContactDetails";
import ContactData from "../../components/global/ContactData";
import useApi from "../../hook/useApi";
import Loader from "../../components/Loader";

const Support = () => {
  const { request, response, loading } = useApi();
  const [Support, setSupport] = useState();
  useEffect(() => {
    request("GET", "get_support_info");
  }, []);
  useEffect(() => {
    if (response) {
      if (response.response.data) {
        const appSub = response.response.data;
        setSupport(appSub);
      }
    }
  }, [response]);
  return (
    <>
    {loading ? <Loader /> : 
    <>
      <Banner
        title={Support?.banner_title}
        heading={Support?.banner_sub_title}
        // subheading="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum"
        // Fbutton="Request a Demo"
        // Furl={navFreeDemo}
        // Lbutton="Get a Free Estimate"
        // Lurl={navPricing}
        bgImage={Support?.banner_image}
      />

      <section className="support_form">
        <Container>
          <Row>
            <Col md={6}>
              <ContactDetails
                heading="Contact Details"
                address={Support?.location}
                email={Support?.email}
                phone={Support?.phone}
                social={Support}
              />
            </Col>
            <Col md={6}>
              <SupportForm />
            </Col>
          </Row>
        </Container>
      </section>
      </>
}
    </>
  );
};

export default Support;
