import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FeaturesCard from "./FeaturesCard";
import FeaturesData from "./FeaturesData";

const FeatureSection = (props) => {
  // const [feaData, setFeaData] = useState(props?.Feature);

  return (
    <div className="feature_section py-5">
      <Container>
        <h2 className="section_heading">{props.secHeading}</h2>
        <p className="section_subheading">{props.secSubheading}</p>

        <Row>
          <Col>
            <div className="features_cards">
              <FeaturesCard feaData={props?.Feature} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FeatureSection;
