import React from "react";
import { Form } from "react-bootstrap";
import { Submit } from "../../components/Button";
import { Heading } from "../../components/Headings";

const ContactForm = (props) => {
  const data = `${props.content}`;

  return (
    <>
      <div className="contact_form">
        <Heading label={props.heading} />

        <div dangerouslySetInnerHTML={{ __html: data }} className="content" />

        <Form className="custom_contact_form">
          <div className="col2_field">
            <Form.Group className="mb-3" controlId="formName">
              <Form.Control type="text" placeholder="Name" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Control type="email" placeholder="email" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Control type="text" placeholder="Phone" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCompany">
              <Form.Control type="text" placeholder="Company" />
            </Form.Group>
          </div>

          <Form.Group className="mb-3" controlId="formMessage">
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="How can i help you?"
            />
          </Form.Group>

          <Submit label="Submit" />
        </Form>
      </div>
    </>
  );
};

export default ContactForm;
