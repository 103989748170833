import React from "react";
import Banner from "../../../components/Banner";
import ImageWithText from "../../../components/ImageWithText";

const RemoteWork = () => {
  return (
    <>
      <Banner
        title="Using Digital Signage as"
        heading="Virtual Screen for Remote Work"
        // subheading="Are you ready to wow customers with digital signage for retail?"
        // Fbutton="Request a Demo"
        // Furl={navFreeDemo}
        bgImage="images/bannerImage.webp"
      />

      <ImageWithText
        imageUrl="images/remote.jpg"
        heading="Remote Work"
        content="<p>Stay connected with your employees wherever they are. Share office communication screens with your employees via URL links or embed them in your company's portal. You also have the ability to set it as a scheduled tile on your desktop and create scheduler rules to specify when the screens should appear.</p><p>Your company’s digital signage can be accessed everywhere. With a single URL link, you can share relevant content with your team members regardless of their location. Your employees can change their device or browser, giving them full access to information on all screens.</p><p>With digital signage, your employees will always keep in touch with you and your brand. You can track employee feedback and motivate team members, as well as announce upcoming events or let everyone know of the latest achievements or awards.</p> <p>Employees work 24/7 and it’s important to be aware of what they are doing. With digital signage, you can create reminders of important dates, tasks, and responsibilities. Remind your employees of these dates and tasks to reduce last-minute stress for everyone!</p>"
      />
    </>
  );
};

export default RemoteWork;
