import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading } from "../../../components/Headings";
import PriceData from "./PriceData";
import PriceCards from "./PriceCards";
import Register from "../../../components/Form/Register";

const DsPricing = ({page_title,page_sub_title,price_list}) => {
  const [priceData, setPriceData] = useState(PriceData);
  const [regForm, setRegForm] = useState(false);

  function showForm() {
    setRegForm(true);
  }

  const data = "Choose a plan that works for you";

  return (
    <>
      <section className="Dspricing_section">
        <Container>
          <Row>
            <Col md={12} className="pricing_content">
              <Heading label={page_title} />
              <div
                dangerouslySetInnerHTML={{ __html: page_sub_title }}
                className="content"
              />
            </Col>

            <Col md={12}>
              <div className="pricing_cards_section">
                <PriceCards priceData={price_list} showForm={showForm} />
              </div>
            </Col>

            {regForm ? (
              <>
                <Col
                  md={12}
                  className="registerForm_section"
                  id="registeration_form"
                >
                  <Register />
                </Col>
              </>
            ) : null}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default DsPricing;
