import React from "react";
import "./style.css";
const Loader = () => {
  return (
    <>
      <div
        style={{
          background: "rgba(1, 1, 1, 0.335)",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1000",
        }}
      >
        <div className="loader" />
      </div>
      {/* <div className="loader-content">
      <div className="loader" />
      </div> */}
    </>
  );
};

export default Loader;
