import React from "react";
import Banner from "../../../components/Banner";
import ImageWithText from "../../../components/ImageWithText";

const Restaurants = () => {
  return (
    <>
      <Banner
        title="Importance of Digital Signage in"
        heading="Restaurant"
        // subheading="Use restaurant digital signage to boost sales (up to 33%) and overall customer experience. "
        // Fbutton="Request a Demo"
        // Furl={navFreeDemo}
        bgImage="images/bannerImage.webp"
      />

      <ImageWithText
        imageUrl="images/restaurant.jpg"
        heading="Restaurant"
        content="<p>At Tech Lab Signage, we believe that digital signage is a powerful way to increase sales, promote your brand, and keep your customers safe.</p> <p>Our integrated software platform allows you to create high-impact menus, promotion templates, and schedules for any time of day or week. With our apps, you can even schedule social media posts and reviews on Instagram and Facebook about your menu items to leverage your content.</p><p>In addition to these tools, we also have hundreds of free menu templates that you can use straightaway. Choose from breakfast, lunch, dinner, weekday or weekend options—whatever works best for your business! Once you've picked one that works for your restaurant's needs, simply edit it with our easy-to-use user interface and push it out to your screens!</p><p>Digital signage is a great way to engage with customers and employees alike—and it's proven that highlight signs on premise increase sales. Our software platform offers an easy way for restaurants interested in digital signage to get started quickly!</p>"
      />
    </>
  );
};

export default Restaurants;
