import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const UsefulLink = ({ footerNav }) => {
  return (
    <>
      {footerNav && footerNav.map(function (elem, i) {
        return (
          <Col md={2} className="footer_block" key={i}>
            <h5>{elem?.navHeading}</h5>

            {elem.menuItems ? (
              <>
                <ul className="footer_menus">
                  {elem.menuItems.map(function (navElem, i) {
                       let url_slug=elem?.navHeading=="Company"?"company/"+navElem.slug:navElem.slug;
                    return (
                      <li className="menu_items" key={i}>
                        <Link to={url_slug}>{navElem.label}</Link>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              ""
            )}
          </Col>
        );
      })}
    </>
  );
};

export default UsefulLink;
