import { useEffect, useState } from "react";
import axios from "axios";
import { constant } from "../constant";

const BACKEND_URL = constant?.baseUrl;

const useApi = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  //   const token = localStorage.getItem("token");

  //   useEffect(() => {
  //     dispatch(
  //       updateLoading({
  //         loading,
  //       })
  //     );
  //   }, [loading]);

  const startFetching = () => {
    setResponse(null);
    setLoading(true);
    setError(null);
    setErrorStatus(null);
  };

  const clear = () => {
    setResponse(null);
    setError(null);
  };

  const fetchedData = () => {
    setLoading(false);
    setError(null);
  };

  const requestData = (method, endPointUrl, data) => {
    setLoading(true);
    let config = {
      method,
      url: `${constant?.baseUrl}${endPointUrl}`,
      headers: {
        // "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        // "AuthToken": "$T23234Lsdfs@#$%^&*(7x[FoTxFT,^Qv&i.m]2YZo$!tTsYfJ",
      },
      withCredentials: false,
      data,
    };

    startFetching();

    axios(config)
      .then((res) => {
        fetchedData();
        setResponse(res.data);
        setLoading(false);
      })
      .catch((err) => {
        fetchedData();
        setErrorStatus(err?.response?.status);
        setError(err?.response?.data?.message);
        if (err?.response?.status === 401) {
          //   dispatch(logout());
          //   notification(ToastType?.ERROR, messages?.AUTH_SESSION_EXPIRED);
          //   navigate("/login", {
          //     replace: true,
          //   });
        }
      });
  };

  return {
    loading,
    error,
    request: requestData, //function
    clear,
    response,
    setError,
    errorStatus,
  };
};

export default useApi;
