import React from "react";
import "./index.css";

export const Heading = (props) => {
  return (
    <>
      <h2 className="sec_heading">{props.label}</h2>
    </>
  );
};

export const SubHeading = (props) => {
  return (
    <>
      <h3 className="sec_subheading">{props.label}</h3>
    </>
  );
};

export const Title = (props) => {
  return (
    <>
      <h4 className="sec_title">{props.label}</h4>
    </>
  );
};
