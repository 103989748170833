import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Submit } from "../../Button";
import { navRegister } from "../../global/Slug";
import { Heading } from "../../Headings";
import "./index.css";

const LoginForm = () => {
  return (
    <>
      <div className="registeration_form">
        <Form>
          <Heading label="Login Now" />
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Control type="text" placeholder="Username" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control type="password" placeholder="Password" />
          </Form.Group>

          <Submit label="Login" />
          <Form.Text className="text-muted">
            <Link to={navRegister}> Create an account </Link>
          </Form.Text>
        </Form>
      </div>
    </>
  );
};

export default LoginForm;
