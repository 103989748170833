import React from "react";
import Banner from "../../../components/Banner";
import ImageWithText from "../../../components/ImageWithText";
import "./index.css";

const GymFitnessCenter = () => {
  return (
    <>
      <Banner
        title="Market"
        heading="Gym and Fitness Centers"
        subheading="Are you a gym owner or manager? Do you want to attract more members and increase revenue?"
        // Fbutton="Request a Demo"
        // Furl={navFreeDemo}
        bgImage="images/bannerImage.webp"
      />

      <div className="gym-and-fitness">
        <ImageWithText
          imageUrl="images/gym.jpg"
          heading="Gym & Fitness"
          content="<p>If so, you need to use digital signage. But how do you make your message memorable, stand out from other businesses, and get your point across?</p> <p><strong>There are several ways to make your message memorable:</strong></p> <ul><li>Display social media reviews from Google, Yelp, Facebook, Instagram, etc. Build trust with your members, and encourage for recommendations on social media.</li><li>Motivate Gym Members. Keep your gym members motivated in the workout. Display motivation quotes, set examples, celebrate the milestone, and share memorable moment on social media.</li><li>Schedule Class Schedule. Communicate with your members about the upcoming class schedule to help them better plan their workout activities. Use Google/Microsoft Calendar? Try our apps.</li></ul> <p>Inform members of upcoming sales, promotions and events easily and quickly with Tech Lab Signage online editor. With the easy-to-use editor, you can easily add information to your digital signage like product descriptions, contact information and more!</p> <p>You can also use Tech Lab Signage pre-built templates to create signs that look professional and match your brand's style. These templates are built with every color scheme in mind so that you'll always be able to find whatever color scheme best suits your needs.</p> "
        />
      </div>
    </>
  );
};

export default GymFitnessCenter;
