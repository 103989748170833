import React from "react";
import "./index.css";
import Banner from "../../components/Banner";

const Shop = () => {
  return (
    <>
      <Banner
        // title="Available"
        heading="Shop"
        // subheading="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum"
        // Fbutton="Request a Demo"
        // Furl={navFreeDemo}
        // Lbutton="Get a Free Estimate"
        // Lurl={navPricing}
        bgImage="images/bannerImage.webp"
      />
    </>
  );
};

export default Shop;
