import React from "react";
import Banner from "../../../components/Banner";
import ImageWithText from "../../../components/ImageWithText";

const Retail = () => {
  return (
    <>
      <Banner
        title="Importance of Digital Signage in"
        heading="Retail"
        // subheading="Are you ready to wow customers with digital signage for retail?"
        // Fbutton="Request a Demo"
        // Furl={navFreeDemo}
        bgImage="images/bannerImage.webp"
      />

      <ImageWithText
        imageUrl="images/retail.jpg"
        heading="Are you ready to wow customers with digital signage for retail?"
        content="<p>If so, we've got some amazing news for you: Tech Lab Signage has free templates for digital signage in retail! Just pick one, edit it, and share it on your screens. You'll be able to highlight promotions, schedule open and closed signs, and influence and attract shoppers with social media content. And if you want to get even more out of your digital signage experience, we have ways to increase engagement with QR codes and touchless displays! </p> <p>If so, we've got some amazing news for you: Tech Lab Signage has free templates for digital signage in retail! Just pick one, edit it, and share it on your screens. You'll be able to highlight promotions, schedule open and closed signs, and influence and attract shoppers with social media content. And if you want to get even more out of your digital signage experience, we have ways to increase engagement with QR codes and touchless displays!</p>"
      />
    </>
  );
};

export default Retail;
