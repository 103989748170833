import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import CheckoutForm from "./CheckoutForm";
import "./index.css";
import OrderDetail from "./OrderDetail";
import PaymentOption from "./PaymentOption";

const Checkout = () => {
  const priceData = useLocation();
  return (
    <>
      <Breadcrumb title="Checkout" />

      <section id="checkout_section">
        <Container>
          <Row>
            <Col md={12} className="checkout_details">
              <CheckoutForm priceData={priceData?.state}/>
            </Col>
            {/* <Col md={5} className="checkout_order_details">
              <OrderDetail />
              <PaymentOption />
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Checkout;
