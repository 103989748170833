import React from "react";
import Card from "react-bootstrap/Card";
import { Link, useNavigate } from "react-router-dom";

const ApplicationCards = ({ appData }) => {
  const navigate = useNavigate();

  const handleSlug = (id) => {
    navigate("details", {
      state: id,
      replace: true,
    });
  };

  return (
    <>
      {appData &&
        appData.slice(0, 8).map(function (elem, i) {
          return (
            <Card key={i}>
              <Link to={"application/" + elem?.url_slug}>
                <div className="card_image">
                  <Card.Img variant="top" src={elem?.image} />
                </div>
              </Link>

              <Card.Body>
                <Link to={"application/" + elem?.url_slug}>
                  <Card.Title>{elem?.title}</Card.Title>
                </Link>
                <Card.Text>
                  <div
                    dangerouslySetInnerHTML={{ __html: elem?.description }}
                    className="section_short_desc"
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          );
        })}
    </>
  );
};

export default ApplicationCards;
