import React, { useEffect, useState } from "react";
import Banner from "./BannerSection";
import ApplicationHome from "./ApplicationSection";
import AboutHome from "./AboutSection";
import BrandHome from "./BrandsSection";
import ServiceHome from "./ServiceSection";
import axios from "axios"; //changes by HG
import {
  navAbout,
  navFreeDemo,
  navContact,
} from "../../components/global/Slug";
import useApi from "../../hook/useApi"; //api calling
import { toast } from "react-toastify"; //toast
import Loader from "../../components/Loader";
const Home = () => {
  const [homeData, sethomeData] = useState();
  const [sevcie, setsevcieData] = useState();
  const [isLoading, setLoading] = useState(false);
  const { request, response } = useApi(); //change
  // const { request: reqUserDetail, response: respUserDetails } = useApi();

  useEffect(() => {
    setLoading(true);
    request("GET", "get_home_page_info");
  }, []);

  useEffect(() => {
    if (response) {
      sethomeData(response.response.data);
      setLoading(false);
    }
  }, [response]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Banner
            Bheading={homeData && homeData.hero_section.title}
            BSubhead={homeData && homeData.hero_section.sub_title}
            Fbutton={homeData && homeData.hero_section.first_button_text}
            Furl={homeData && homeData.hero_section.first_button_url}
            Lbutton={homeData && homeData.hero_section.second_button_text}
            Lurl={homeData && homeData.hero_section.first_button_url}
            BottomText={homeData && homeData.hero_section.bottom_text}
            // bgImage="images/bannerbg.png"
            bgImage={homeData && homeData.hero_section.image}
          />

          <ServiceHome
            SubHeading={homeData && homeData.service.sub_heading}
            Heading={homeData && homeData.service.heading}
            service={homeData && homeData.service.service_data}
          />

          <AboutHome
            SubHeading={homeData && homeData.aboutus.title}
            Heading={homeData && homeData.aboutus.sub_title}
            secDesc={homeData && homeData.aboutus.description}
            btnLabel={homeData && homeData.aboutus.button_text}
            btnSlug={homeData && homeData.aboutus.button_url}
            bgImage={homeData && homeData.aboutus.image_url}
          />

          <ApplicationHome
            SubHeading={homeData && homeData.application.heading}
            Heading={homeData && homeData.application.sub_heading}
            secDesc={homeData && homeData.application.description}
            application={homeData && homeData.application.application_data}
            // btnLabel="View All Apps"
            // btnSlug={navDigitalSignage}
            bgImage="images/bannerbg.png"
          />

          {/* <BrandHome
        SubHeading={homeData && homeData.brand.sub_title}
        Heading={homeData && homeData.brand.title}
        brand={homeData && homeData.brand.brand_data}
      /> */}
        </>
      )}
    </>
  );
};

export default Home;
