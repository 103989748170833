import React, { useState, useEffect } from "react";
import "./index.css";
import Banner from "../../components/Banner";
import useApi from "../../hook/useApi";
import Loader from "../../components/Loader";
import ImageWithText from "../../components/ImageWithText";

const AppSupported = () => {
  const [details, setdetails] = useState();
  const { request, response, loading } = useApi();
  useEffect(() => {
    request("GET", "get_app_supported_info");
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status) {
        setdetails(response.response.data);
      }
    }
  }, [response]);
  return loading ? (
    <Loader />
  ) : (
    <>
      <Banner
        // title="Available"
        heading={details?.banner_title}
        subheading={details?.banner_sub_title}
        // Fbutton="Request a Demo"
        // Furl={navFreeDemo}
        // Lbutton="Get a Free Estimate"
        // Lurl={navPricing}
        bgImage={details?.banner_image}
      />
       <ImageWithText
        imageUrl={details?.app_image}
        heading={details?.app_title}
        content={details?.app_desc}
      />
    </>
  );
};

export default AppSupported;
