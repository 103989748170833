import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Submit } from "../../Button";
import { navPrivacyPolicy, navTermsOfUse, navLogin } from "../../global/Slug";
import { Heading } from "../../Headings";

import "./index.css";

const RegisterForm = () => {
  return (
    <>
      <div className="registeration_form">
        <Form>
          <Heading label="Register Now" />
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Control type="text" placeholder="First Name" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicLname">
            <Form.Control type="text" placeholder="Last Name" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control type="email" placeholder="Enter email" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control type="password" placeholder="Password" />
          </Form.Group>

          <Form.Text className="text-muted">
            By registering, you confirm that you've read and accepted our{" "}
            <Link to={navTermsOfUse}>Terms of Use</Link> and{" "}
            <Link to={navPrivacyPolicy}>Privacy Policy</Link>.
          </Form.Text>

          <Submit label="Register" />
          <Form.Text className="text-muted">Already have an account?</Form.Text>
          <Link to={navLogin} className="outlineButton">
            Login
          </Link>
        </Form>
      </div>
    </>
  );
};

export default RegisterForm;
