import {
  navConferenceRooms,
  navGymFitnessCenters,
  navLobbyAndOffices,
  navRemoteWork,
  navRestaurants,
  navRetail,
  navSchoolAndUniverstiy,
} from "../../../components/global/Slug";

const ApplicationData = [
  {
    label: "Conference Rooms",
    desc: "Tech Lab transforms your meeting experiences with a wide range of digital signage. Create your own custom solution for your meeting room needs and free presenters from dongles and cables, turn TVs into communication channels when rooms aren’t in use, show the schedule of your rooms to prevent conflicts, and more!",
    imageUrl: "images/elevate.jpg",
    slug: navConferenceRooms,
  },

  {
    label: "Remote Work",
    desc: "Stay connected with your employees wherever they are. Share office communication screens with your employees via URL links or embed them in your company's portal. You also have the ability to set it as a scheduled tile on your desktop and create scheduler rules to specify when the screens should appear.",
    imageUrl: "images/remote.jpg",
    slug: navRemoteWork,
  },

  {
    label: "Retail",
    desc: "If so, we've got some amazing news for you: Tech Lab Signage has free templates for digital signage in retail! Just pick one, edit it, and share it on your screens. You'll be able to highlight promotions, schedule open and closed signs, and influence and attract shoppers with social media content. And if you want to get even more out of your digital signage experience, we have ways to increase engagement with QR codes and touchless displays!",
    imageUrl: "images/retail.jpg",
    slug: navRetail,
  },

  {
    label: "Restaurant",
    desc: "At Tech Lab Signage, we believe that digital signage is a powerful way to increase sales, promote your brand, and keep your customers safe.",
    imageUrl: "images/restaurant.jpg",
    slug: navRestaurants,
  },
  {
    label: "Lobby and Office",
    desc: "Tech Lab Signage is a sophisticated yet simple way to create beautiful content and manage your digital signs for your business.",
    imageUrl: "images/office.jpg",
    slug: navLobbyAndOffices,
  },
  {
    label: "Gym & Fitness Centers",
    desc: "If so, you need to use digital signage. But how do you make your message memorable, stand out from other businesses, and get your point across?",
    imageUrl: "images/gym.jpg",
    slug: navGymFitnessCenters,
  },
  {
    label: "School & University",
    desc: "The application or use of digital signage for schools & education are numerous.The displays can be used as informational boards, welcome boards and announcement boards.",
    imageUrl: "images/office.jpg",
    slug: navSchoolAndUniverstiy,
  },
];

export default ApplicationData;
