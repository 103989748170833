import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./index.css";

export const PrimaryButton = (props) => {
  // You should use <Link> only with Router to let user navigate in your App.
  //If you want to redirect a user to an external url, use the <a> tag
  return props.onClickbtn ? (
    <Link
      to={props?.slug}
      className="primaryButton"
      onClick={props.onClickbtn}
      type={props.type}
    >
      {props.label}
    </Link>
  ) : (
    <a
      href={props?.slug}
      className="primaryButton"
      // target="_blank"
      type={props.type}
    >
      {props.label}
    </a>
  );
};

export const OutlineButton = (props) => {
  return props.onClickbtn ? (
    <Link
      to={props?.slug}
      className="outlineButton"
      type={props.type}
      onClick={props.onClickbtn}
    >
      {props.label}
    </Link>
  ) : (
    <a
      href={props?.slug}
      className="outlineButton"
      // target="_blank"
      type={props.type}
    >
      {props.label}
    </a>
  );
};

export const Submit = (props) => {
  return (
    <>
      {props.loading ? (
        <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading...
        </Button>
      ) : (
        <Button className="primaryButton" type="submit">
          {props.label}
        </Button>
      )}
    </>
  );
};
