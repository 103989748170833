// import React, { useEffect } from "react";
import MenuItems from "./MenuItems";
// import NavItems from "./NavItems";
import { useState, useEffect } from "react";
import "./index.css";
import useApi from "../../hook/useApi";
import {
  navPricing,
  navAbout,
  navHome,
  navFeatures,
  navDownloadsAndLinks,
  navAppSupported,
  navShop,
  navFaqs,
  navSupport,
  navConferenceRooms,
  navOnsiteSupport,
} from "../global/Slug";

const Header = () => {
  // const [menuData, setMenuData] = useState(NavItems);
  const [applicationSubMenu, setApplicationMenu] = useState([]);
  const { request, response, loading } = useApi();

  let arr = [];
  let NavItems = [
    {
      label: "Home",
      slug: navHome,
      isDropdown: false,
      megaMenu: false,
    },
    {
      label: "About Us",
      slug: navAbout,
      isDropdown: false,
      megaMenu: false,
    },
    {
      label: "Downloads & Links",
      slug: navDownloadsAndLinks,
      isDropdown: false,
      megaMenu: false,
    },
    {
      label: "Pricing",
      slug: navPricing,
      isDropdown: false,
      megaMenu: false,
    },
    {
      label: "Apps Supported",
      slug: navAppSupported,
      isDropdown: true,
      megaMenu: false,
      dropDown: [
        {
          label: "About Apps",
          slug: navAppSupported,
          isDropdown: false,
          megaMenu: false,
        },
        {
          label: "Features",
          slug: navFeatures,
          isDropdown: false,
          megaMenu: false,
        },
      ],
    },
    {
      label: "Applications",
      slug: "#",
      isDropdown: true,
      megaMenu: false,
      // gridCol: "3",
      dropDown: arr,
    },
    {
      label: "Shop Hardware",
      slug: navShop,
      isDropdown: false,
      megaMenu: false,
      redirect: true,
      target: "_blank",
    },
    {
      label: "FAQs",
      slug: navFaqs,
      isDropdown: false,
      megaMenu: false,
    },
    {
      label: "Support",
      slug: navSupport,
      isDropdown: true,
      megaMenu: false,
      dropDown: [
        {
          label: "Contact Us",
          slug: navSupport,
          isDropdown: false,
          megaMenu: false,
        },
        {
          label: "Onsite Support",
          slug: navOnsiteSupport,
          isDropdown: false,
          megaMenu: false,
        },
      ],
    },
  ];

  useEffect(() => {
    request("GET", "get_home_page_info");
  }, [window.location]);

  useEffect(() => {
    if (response) {
      const appSub = response.response.data.application.application_data;
      appSub?.map((data) => {
        arr.push({
          label: data.title,
          slug: navConferenceRooms,
          id: data.url_slug,
        });
      });
      setApplicationMenu(NavItems);
    }
  }, [response]);

  return (
    <>
      {/* <NavbarMenu menuData={menuData} /> */}
      <MenuItems menuData={applicationSubMenu} />
    </>
  );
};

export default Header;
