import React from "react";
import { Link } from "react-router-dom";
import { Heading } from "../Headings";
import "./index.css";

const IconWithText = (props) => {
  return (
    <div className="icon_section">
      <img src={props.imageUrl} style={{ width: "100%" }} />
      <Heading label={props.heading} />
      <p className="Iconcard_desc">
        <a href={props.slug}>{props.desc}</a>
      </p>
    </div>
  );
};

export default IconWithText;
