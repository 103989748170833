import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import "./index.css";
import ContactForm from "./ContactForm";
import { Col, Container, Row } from "react-bootstrap";
import ContactDetails from "./ContactDetails";
import ContactData from "../../components/global/ContactData";
const Contact = () => {
  return (
    <>
      <Breadcrumb title="Contact Us" />
      <section className="contact_section">
        <Container>
          <Row>
            <Col md={6} className="contact_detail_section">
              <ContactDetails
                heading="Contact Details"
                address={ContactData[2].desc}
                email={ContactData[1].desc}
                phone={ContactData[0].desc}
              />
            </Col>
            <Col md={6}>
              <ContactForm
                heading="Contact Sales"
                content="Fill out the form below or call us at 999 999 999 to contact an Techlab Consultant about your Digital Signage needs."
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact;
