import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PrimaryButton } from "../Button";
import { Heading } from "../Headings";
import "./index.css";

const ImageWithText = (props) => {
  const data = `${props.content}`;
  return (
    <section className="image_with_text_section">
      <Container>
        <Row
        // style={{ alignItems: "center" }}
        >
          <Col md={6}>
            <div className="image_section">
              <img src={props.imageUrl} style={{ width: "100%" }} />
            </div>
          </Col>
          <Col md={6}>
            <div className="content_section">
              <Heading label={props.heading} />
              <div
                dangerouslySetInnerHTML={{ __html: data }}
                className="content"
              />
              {props.btnLabel ? (
                <PrimaryButton label={props?.btnLabel} slug={props?.btnUrl} />
              ) : (
                " "
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ImageWithText;
