import React from "react";
import Banner from "../../../components/Banner";
import ImageWithText from "../../../components/ImageWithText";

const SchoolAndUniversity = () => {
  return (
    <>
      <Banner
        title="Importance of Digital Signage in"
        heading="School & University"
        // subheading="57% of communications professionals are using corporate digital signage solutions which leads to 21% higher productivity."
        // Fbutton="Request a Demo"
        // Furl={navFreeDemo}
        bgImage="images/bannerImage.webp"
      />

      <ImageWithText
        imageUrl="images/office.jpg"
        heading="School & University"
        content="<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>"
      />
    </>
  );
};

export default SchoolAndUniversity;
