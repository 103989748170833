import React, { useEffect, useState } from "react";
import FooterAction from "./FooterAction";
import FooterBottom from "./FooterBottom";
import Copyright from "./Copyright";
import { navContact } from "../global/Slug";
import ContactData from "../global/ContactData";
import "./index.css";
import useApi from "../../hook/useApi";
import logo from '../../image/logo.png';
const Footer = () => {
  const { request, response, loading } = useApi();
  const [Callto, setCallto] = useState();
  useEffect(() => {
    request("GET", "get_support_info");
  }, []);
  useEffect(() => {
    if (response) {
      if (response.response.data) {
        const appSub = response.response.data;
        setCallto(appSub);
      }
    }
  }, [response]);
  const CopyRightText=`Tech Lab Signage © ${new Date().getFullYear()} All Rights Reserved.`;
  return (
    <>
      <FooterAction
        secHeading={Callto?.callto_titlle}
        secSubheading={Callto?.callto_sub_titlle}
        secDesc="Lorem Ipsum is simply dummy textof the printing and typesetting industry."
        fBtnLabel={Callto?.callto_button_text}
        fBtnSlug={Callto?.callto_button_url}
        // lBtnLabel="Book a Demo"
        // lBtnSlug={navContact}
      />

      <FooterBottom
        logo={logo}
        // logo={process.env.PUBLIC_URL+"/images/logo.png"}
        contactHeading="Contact"
        phone={Callto?.phone}
        email={Callto?.email}
        address={Callto?.location}
      />

      <Copyright copyText={CopyRightText} />
    </>
  );
};

export default Footer;
