import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UsefulLink from "./UsefulLink";
import FooterNav from "./FooterNavData";
import { Link } from "react-router-dom";
import {
  navPricing,
  navAbout,
  navHome,
  navTermsOfUse,
  navSecurity,
  navPrivacyPolicy,
  navReturnPolicy,
  navContact,
  navFeatures,
  navFaqs,
} from "../global/Slug";
import {
  ConeStriped,
  Envelope,
  MapFill,
  Phone,
  PinMap,
} from "react-bootstrap-icons";
import useApi from "../../hook/useApi";

const FooterBottom = (props) => {
  const { request, response, loading } = useApi();
  const [Page, setPage] = useState([]);
  let arr = [];
  useEffect(() => {
    request("GET", "get_info_page");
  }, []);
  useEffect(() => {
    if (response) {
      if (response.response.data) {
        const pageData = response.response.data;
        pageData?.map((data) => {
          arr.push({
            label: data?.title,
            slug: data?.slug,
          });
        });
        let nav = [
          {
            navHeading: "Company",
            menuItems: arr,
          },
        ];
        setPage(nav);
      }
    }
  }, [response]);

  return (
    <>
      <div className="footer_bottom_section py-5">
        <Container>
          <Row>
            <Col md={3} className="footer_block">
              <img src={props.logo} style={{ maxWidth: "200px" }} />
            </Col>

            <UsefulLink footerNav={FooterNav} />
            {/* for company senction */}
            {Page && <UsefulLink footerNav={Page} />}

            <Col md={3} className="footer_block contact_block">
              <h5>{props.contactHeading}</h5>
              <ul className="contactDetails">
                <li>
                  <Envelope />
                  <a href={`mailto:${props.email}`}> {props.email}</a>
                </li>
                <li>
                  <Phone />
                  <a href={`tel:${props.phone}`}>{props.phone}</a>
                </li>
                <li>
                  <PinMap />
                  {props.address}
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FooterBottom;
